<template>
	<div>
		<a :href="'mailto:' + emailAddress" class="custom-button">Say Hello</a>
	</div>
</template>

<script>
export default {
	name: "Button",
	data() {
		return {
			emailAddress: "tommy.lohil.me@gmail.com",
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.custom-button {
	background-color: transparent;
	color: $blue-contrast-1;
	border: 2px solid $blue-contrast-1;
	border-radius: 8px;
	padding: 15px 20px;
	text-decoration: none;
}

.custom-button:hover {
	background-color: transparent;
	color: $blue-contrast-2;
	border: 2px solid $blue-contrast-2;
	border-radius: 8px;
	padding: 15px 20px;
}
</style>

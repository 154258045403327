<template>
	<div style="display: flex; flex-direction: column">
		<div style="display: flex; flex-direction: row">
			<h1 class="title" id="reach-me">Reach Me</h1>
			<div class="title__line"></div>
		</div>
		<p class="reach-me">
			I am interested in freelance project or any opportunities. Don't hestitate to e-mail me, I will try my
			best to reply you!
		</p>
		<Button class="reach-me__button" />
	</div>
</template>

<script>
import Button from "./Button.vue";

export default {
	name: "ReachMe",
	components: {
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.reach-me {
	max-width: 470px;
	margin: 0 auto;
	text-align: center;
	font-size: clamp(16px, 4vw, 20px);
	line-height: 1.6;
}

.reach-me__button {
	margin: 100px auto 0 auto;
}

.title {
	font-size: clamp(40px, 5vw, 60px);
}

@media (max-width: 480px) {}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
</style>

<template>
	<div class="big-container">
		<header id="header-nav-bar">
			<NavBar />
		</header>
		<SocialMediaVertical />
		<main id="content">
			<section class="content__section content__section--top">
				<Home id="home" />
			</section>
			<section class="content__section">
				<AboutMe />
			</section>
			<section class="content__section">
				<Experience />
			</section>
			<section class="content__section">
				<Project />
			</section>
			<section class="content__section content__section--bottom">
				<ReachMe />
			</section>
			<SocialMediaHorizontal />
			<footer>
				<p class="footer__author">
					Built by Tommy Lohil, design inspired by Brittany Chiang
				</p>
			</footer>
		</main>
	</div>
</template>

<script>
import Home from "./components/Home.vue";
import NavBar from "./components/NavBar.vue";
import SocialMediaVertical from "./components/SocialMediaVertical.vue";
import SocialMediaHorizontal from "./components/SocialMediaHorizontal.vue";
import AboutMe from "./components/AboutMe.vue";
import Experience from "./components/Experience.vue";
import Project from "./components/Project.vue";
import ReachMe from "./components/ReachMe.vue";

export default {
	name: "App",
	components: {
		Home,
		NavBar,
		SocialMediaVertical,
		SocialMediaHorizontal,
		AboutMe,
		Experience,
		Project,
		ReachMe,
	},
};

/* When the user scrolls down, hide the navbar. When the user scrolls up, show the navbar */
var prevScrollpos = window.pageYOffset;
window.onscroll = function () {
	var currentScrollPos = window.pageYOffset;
	if (prevScrollpos > currentScrollPos) {
		document.getElementById("header-nav-bar").style.top = "0px";
		document.getElementById("header-nav-bar").style.transition = "all 0.5s cubic-bezier(0.645,0.045,0.355,1)";
	} else {
		document.getElementById("header-nav-bar").style.top = "-100px";
		document.getElementById("header-nav-bar").style.transition = "all 0.5s cubic-bezier(0.645,0.045,0.355,1)";
	}
	prevScrollpos = currentScrollPos;
};
</script>

<style lang="scss">
#app {
	font-family: "montserrat", Helvetica;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
}

.big-container {
	display: flex;
	flex-direction: column;
}

#header-nav-bar {
	display: flex;
	width: 100%;
	z-index: 10;
	top: 0px;
	height: 75px;
	align-items: center;
	backdrop-filter: blur(10px);
	position: fixed;
	box-shadow: 0 10px 30px -10px $base-blue-4;
}

#content {
	height: 100vh;
	background-color: $base-blue-1;
	filter: blur(0px);
	transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.content__section {
	padding: 150px 0;
}

.content__section--top {
	padding-top: 100px;
}

.content__section--bottom {
	padding-bottom: 400px;
}

.content--blur {
	filter: blur(5px) !important;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.footer__author {
	font-size: 12px;
	text-align: center;
	padding-bottom: 20px;
}

/*
	Using Bootstrap responsive breakpoints standards
	https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
	but some breakpoints are customized by Tommy
*/
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
	#content {
		padding: 0 20px;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {
	#content {
		padding: 0 50px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	#content {
		padding: 0 80px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	#content {
		padding: 0 140px;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	#content {
		padding: 0 160px;
	}
}
</style>

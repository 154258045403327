<template>
	<div class="social-media social-media--vertical">
		<ul class="social-media__ul social-media__ul--vertical">
			<li class="social-media__li">
				<a v-bind:href="githubLink" target="_blank"><img :src="githubIcon" alt="github"
						class="social-media__image social-media__image--vertical" /></a>
			</li>
			<li class="social-media__li">
				<a v-bind:href="linkedInLink" target="_blank"><img :src="linkedInIcon" alt="linked-in"
						class="social-media__image social-media__image--vertical" /></a>
			</li>
			<li class="social-media__li">
				<a v-bind:href="instagramLink" target="_blank"><img :src="instagramIcon" alt="instagram"
						class="social-media__image social-media__image--vertical" /></a>
			</li>
			<li class="social-media__li">
				<a v-bind:href="lineLink" target="_blank"><img :src="lineIcon" alt="line"
						class="social-media__image social-media__image--vertical" /></a>
			</li>
			<div class="social-media__line--vertical"></div>
		</ul>
	</div>
</template>

<script>
export default {
	name: "SocialMediaVertical",
	data() {
		return {
			githubIcon: require("../assets/social-media/github.svg"),
			linkedInIcon: require("../assets/social-media/linkedin.svg"),
			instagramIcon: require("../assets/social-media/instagram.svg"),
			lineIcon: require("../assets/social-media/line.svg"),
			githubLink: "https://github.com/tommylohil",
			linkedInLink: "https://www.linkedin.com/in/tomloh",
			instagramLink: "https://www.instagram.com/tommylohil/",
			lineLink: "https://line.me/ti/p/~tommylohil",
		};
	},
};
</script>

<style lang="scss">
.social-media {
	color: $white;
	z-index: 5;
}

.social-media--vertical {
	position: fixed;
	display: block;
	width: 50px;
	right: 40px;
	bottom: 0px;
}

.social-media__ul {
	display: flex;
	margin: 0px;
	padding: 0px;
	align-items: center;
}

.social-media__ul--vertical {
	flex-direction: column;
}

.social-media__li {
	list-style: none;
}

.social-media__image {
	width: 35px;
}

.social-media__image--vertical {
	transform: translateY(0px);
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	margin: 5px 0;
}

.social-media__line--vertical {
	display: block;
	width: 1px;
	height: 20vh;
	margin: auto;
	background-color: #d0d9e6;
}

.social-media__image:hover {
	transform: translateY(-3px);
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*
	Using Bootstrap responsive breakpoints standards
	https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
	but customized by me
*/
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
	.social-media--vertical {
		display: none;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {
	.social-media--vertical {
		display: none;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
</style>

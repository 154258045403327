<template>
	<div>
		<div style="display: flex; flex-direction: row">
			<h1 class="title" id="experience">Experience</h1>
			<div class="title__line"></div>
		</div>
		<div class="experience">
			<div class="block">
				<el-timeline :reverse="reverse">
					<el-timeline-item v-for="(activity, index) in activities" :key="index"
						:timestamp="activity.timestamp" placement="top">
						<el-card>
							<h4>{{ activity.title }}</h4>
							<ul>
								<li v-for="description in activity.description" :key="description.message">
									<p>{{ description.message }}</p>
								</li>
							</ul>
						</el-card>
					</el-timeline-item>
				</el-timeline>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AboutMe",
	data() {
		return {
			reverse: true,
			activities: [
				{
					timestamp: "April 2019 - October 2019",
					title: "BiteTribe - Backend developer",
					description: [
						{ message: "Developed admin side of F&B startup project using Laravel." },
						{ message: "Contributed in database design structure." }
					],
				},
				{
					timestamp: "November 2019 - October 2020",
					title: "PT. Arsitektur Sistem Teknologi - Full-Stack developer",
					description: [
						{ message: "Developed APIs for medical app, including simple inventory system and reports." },
						{ message: "Built company profile and admin system for web project in Laravel and Vue.js." }
					],
				},
				{
					timestamp: "July 2021 - October 2021",
					title: "Blibli.com - Software Development Engineer Internship",
					description: [
						{ message: "Explored and Learned technologies used by e-commerce." }
					],
				},
				{
					timestamp: "April 2022 - Present",
					title: "Blibli.com - Software Development Engineer",
					description: [
						{ message: "Maintain and develop backend system of homepage, category, promotion, and flashsale page." },
						{ message: "Implement performant and clean code." }
					],
				},
			],
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.experience {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.el-timeline {
	padding-left: 0px;
}

.el-timeline-item__timestamp {
	color: $base-blue-2 !important;
	font-weight: 500;
	font-size: clamp(14px, 4vw, 18px) !important;
}

.el-card__body {
	background-color: $base-blue-5;
	border-style: none !important;
	color: $base-blue-2;
}

.el-card__body>h4 {
	margin: 0px 0 10px 0;
	font-size: 16px;
}

.el-card__body>ul {
	padding-left: 20px;
}

.el-card__body>ul>li>p {
	margin: 5px 0;
}

.title {
	font-size: clamp(40px, 5vw, 60px);
}

ul {
	list-style-type: square;
}

@media (max-width: 480px) {}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
</style>

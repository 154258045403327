<template>
	<nav class="nav-bar">
		<img :src="tommyLogo" alt="tommy-logo" class="nav-bar__tommy-logo" />
		<div class="nav-bar__horizontal">
			<ol>
				<li @click="scrollTo('about-me')"><a>About me</a></li>
				<li @click="scrollTo('experience')"><a>Experience</a></li>
				<li @click="scrollTo('project')"><a>Project</a></li>
				<li @click="scrollTo('reach-me')"><a>Contact</a></li>
			</ol>
		</div>
		<div class="nav-bar__vertical">
			<div class="nav-bar__mobile-menu-button">
				<img :src="imageSrc" alt="menu-button" class="hamburger-menu" @click="toggleVerticalNavBar()" />
			</div>
			<aside id="aside" class="nav-bar__aside">
				<nav class="vertical-nav-bar">
					<ol class="vertical-nav-bar__ol">
						<li class="vertical-nav-bar__li" @click="toggleVerticalNavBar(); scrollTo('about-me');">
							<a>About me</a>
						</li>
						<li class="vertical-nav-bar__li" @click="toggleVerticalNavBar(); scrollTo('experience');">
							<a>Experience</a>
						</li>
						<li class="vertical-nav-bar__li" @click="toggleVerticalNavBar(); scrollTo('project');">
							<a>Project</a>
						</li>
						<li class="vertical-nav-bar__li" @click="toggleVerticalNavBar(); scrollTo('reach-me');">
							<a>Contact</a>
						</li>
					</ol>
				</nav>
			</aside>
		</div>
	</nav>
</template>

<script>
import $ from "jquery";
import Vue from "vue";
export default {
	name: "Navbar",
	data() {
		return {
			tommyLogo: require("../assets/tl-logo.svg"),
			hamburgerMenu: require("../assets/hamburger-menu.svg"),
			closeMenu: require("../assets/close-menu.svg"),
			imageSrc: require("../assets/hamburger-menu.svg"),
		};
	},
	methods: {
		toggleVerticalNavBar() {
			const body = document.body;

			Vue.prototype.$isSideBarActivated = !Vue.prototype.$isSideBarActivated;
			if (Vue.prototype.$isSideBarActivated) {
				// Show sidebar
				document.querySelector("#content").classList.add("content--blur");
				document.querySelector("#aside").classList.add("active");
				body.style.height = "100vh";
				body.style.overflowY = "hidden";

				// Change hamburger-menu to close-menu
				this.imageSrc = this.closeMenu;
				document.querySelector(".hamburger-menu").classList.add("hamburger-menu--smaller");
			} else {
				// Hide sidebar
				document.querySelector("#content").classList.remove("content--blur");
				document.querySelector("#aside").classList.remove("active");
				body.style.height = "100%";
				body.style.overflowY = "auto";

				// Change close-menu to hamburger-menu
				this.imageSrc = this.hamburgerMenu;
				document.querySelector(".hamburger-menu").classList.remove("hamburger-menu--smaller");
			}
		},
		scrollTo(sectionId) {
			const element = document.querySelector("#" + sectionId);
			element.scrollIntoView({ behavior: "smooth", block: "start" });
		},
	},
};

window.onload = function () {
	Vue.prototype.$isSideBarActivated = false;
};

$('body').click(function (event) {
	if (!$(event.target).closest('#openModal').length && !$(event.target).is('#openModal')) {
		$(".modalDialog").hide();
	}
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
a {
	text-decoration: none;
	color: $base-blue-2;
}

.nav-bar {
	width: 100%;
	max-height: 50px;
	display: flex;
	justify-content: space-between;
	box-sizing: border-box;
}

.nav-bar__horizontal {
	display: block;
}

.nav-bar__vertical {
	display: none;
}

.nav-bar__tommy-logo {
	max-width: 40px;
	max-height: 40px;
}

.hamburger-menu {
	width: 40px;
	height: 40px;
	transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.hamburger-menu--smaller {
	padding: 5px;
	transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.nav-bar__horizontal>ol {
	display: flex;
	flex-direction: row;
	align-items: center;
}

li {
	list-style: none;
	cursor: pointer;
}

.nav-bar__horizontal>ol>li>a {
	margin: 10px;
	font-size: 14px;
}

.nav-bar__vertical>ol {
	display: none;
}

.nav-bar__mobile-menu-button {
	display: flex;
	z-index: 30;
}

.nav-bar__aside {
	display: flex;
	align-content: center;
	position: fixed;
	z-index: -1;
	top: 0px;
	bottom: 0px;
	right: 0px;
	padding: 50px 10px;
	height: 100vh;
	width: min(75vw, 400px);
	background-color: $base-blue-5;
	transform: translate(100vw);
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.vertical-nav-bar {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
	text-align: center;
}

.vertical-nav-bar__ol {
	margin: 0px;
	padding: 0px;
	width: 100%;
	margin-top: 50px;
}

.vertical-nav-bar__li {
	width: 100%;
	margin-bottom: 10px;
	padding: 20px 0 20px 0;
	font-size: clamp(14px, 4vw, 18px);
}

.active {
	transform: translate(0vw);
	transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
}

/*
	Using Bootstrap responsive breakpoints standards
	https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
	but customized by me
*/
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
	.nav-bar {
		padding: 0 25px;
		height: 100vh;
	}

	.nav-bar__horizontal {
		display: none;
	}

	.nav-bar__vertical {
		display: block;
	}

	.nav-bar__vertical>ol {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {
	.nav-bar {
		padding: 0 50px;
		height: 100vh;
	}

	.nav-bar__horizontal {
		display: none;
	}

	.nav-bar__vertical {
		display: block;
	}

	.nav-bar__vertical>ol {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.nav-bar {
		padding: 0 75px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	.nav-bar {
		padding: 0 90px;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.nav-bar {
		padding: 0 100px;
	}
}
</style>

<template>
	<div>
		<p id="hi">Hi, my name is</p>
		<h1>Tommy Lohil</h1>
		<h2>A Software Engineer.</h2>
		<p id="home-description">
			I'm a conscientious software engineer focused on building scalable and performant application
			systems. Currently, I'm working at Blibli.com as Software Development Engineer.
		</p>
		<Button id="home-say-hello" />
	</div>
</template>

<script>
import Button from "./Button.vue";

export default {
	name: "Home",
	components: {
		Button,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
	font-weight: 700;
	color: $base-blue-2;
	font-size: clamp(40px, 8vw, 60px);
}

h2 {
	font-weight: 700;
	font-size: clamp(30px, 7vw, 50px);
}

#hi {
	font-size: clamp(16px, 4vw, 18px);
}

#home-description {
	font-size: clamp(16px, 4vw, 20px);
	max-width: 680px;
	line-height: 1.6;
}

@media (max-width: 480px) {
	h1 {
		margin: 10px 0 0 0;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	#home-say-hello {
		margin-top: 100px;
	}

	#home-description {
		margin: 35px 0 25px 0;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {
	h1 {
		margin: 10px 0 0 0;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	#home-say-hello {
		margin-top: 100px;
	}

	#home-description {
		margin: 35px 0 25px 0;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	h1 {
		margin: 10px 0 0 0;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	#home-say-hello {
		margin-top: 100px;
	}

	#home-description {
		margin: 35px 0 25px 0;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	h1 {
		margin: 10px 0 0 0;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	#home-say-hello {
		margin-top: 100px;
	}

	#home-description {
		margin: 40px 0 30px 0;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	h1 {
		margin: 10px 0 0 0;
	}

	h2 {
		margin: 10px 0 0 0;
	}

	#home-say-hello {
		margin-top: 100px;
	}

	#home-description {
		margin: 40px 0 30px 0;
	}
}
</style>

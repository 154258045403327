<template>
	<div class="social-media social-media--horizontal">
		<ul class="social-media__ul social-media__ul--horizontal">
			<div class="social-media__line--horizontal"></div>
			<li class="social-media__li social-media__li--left">
				<a v-bind:href="githubLink" target="_blank"><img :src="githubIcon" alt="github"
						class="social-media__image social-media__image--horizontal" /></a>
			</li>
			<li class="social-media__li">
				<a v-bind:href="linkedInLink" target="_blank"><img :src="linkedInIcon" alt="linked-in"
						class="social-media__image social-media__image--horizontal" /></a>
			</li>
			<li class="social-media__li">
				<a v-bind:href="instagramLink" target="_blank"><img :src="instagramIcon" alt="instagram"
						class="social-media__image social-media__image--horizontal" /></a>
			</li>
			<li class="social-media__li social-media__li--right">
				<a v-bind:href="lineLink" target="_blank"><img :src="lineIcon" alt="line"
						class="social-media__image social-media__image--horizontal" /></a>
			</li>

			<div class="social-media__line--horizontal"></div>
		</ul>
	</div>
</template>

<script>
export default {
	name: "SocialMediaHorizontal",
	data() {
		return {
			githubIcon: require("../assets/social-media/github.svg"),
			linkedInIcon: require("../assets/social-media/linkedin.svg"),
			instagramIcon: require("../assets/social-media/instagram.svg"),
			lineIcon: require("../assets/social-media/line.svg"),
			githubLink: "https://github.com/tommylohil",
			linkedInLink: "https://www.linkedin.com/in/tomloh",
			instagramLink: "https://www.instagram.com/tommylohil/",
			lineLink: "https://line.me/ti/p/~tommylohil",
		};
	},
};
</script>

<style lang="scss">
.social-media--horizontal {
	display: block;
	width: 100%;
}

.social-media__ul--horizontal {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.social-media__image--horizontal {
	margin: 0 5px;
}

.social-media__line--horizontal {
	display: block;
	width: 25%;
	height: 1px;
	margin: auto;
	background-color: $base-blue-2;
}

.social-media__li--left {
	margin: 0 5px 0 10px;
}

.social-media__li--right {
	margin: 0 10px 0 5px;
}

/*
	Using Bootstrap responsive breakpoints standards
	https://getbootstrap.com/docs/4.1/layout/overview/#responsive-breakpoints
	but customized by me
*/
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 480px) {
	.social-media__image {
		width: 30px;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.social-media--horizontal {
		display: none;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	.social-media--horizontal {
		display: none;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.social-media--horizontal {
		display: none;
	}
}
</style>

<template>
	<div>
		<div style="display: flex; flex-direction: row">
			<h1 class="title" id="about-me">About me</h1>
			<div class="title__line"></div>
		</div>
		<div class="about-me">
			<img :src="tommyLohil" alt="tommy-lohil" class="about-me__picture" />
			<p class="about-me__description">
				Hi there, My name is Tommy Lohil. I enjoy building great software to digitize
				business system into reality.
				<br />
				<br />
				Technologies I have been working with:
				<el-row>
					<el-col :span="12">
						<ul>
							<li>JavaScript</li>
							<li>Vue.js</li>
							<li>Java</li>
							<li>Sprint Boot</li>
							<li>Laravel</li>
						</ul>
					</el-col>
					<el-col :span="12">
						<ul>
							<li>Kotlin</li>
							<li>ElasticSearch</li>
							<li>PostgreSQL</li>
							<li>MongoDB</li>
						</ul>
					</el-col>
				</el-row>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: "AboutMe",
	data() {
		return {
			tommyLohil: require("../assets/tommy-lohil.jpg"),
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.title {
	font-weight: 500;
	font-size: clamp(40px, 5vw, 60px);
	color: $base-blue-2;
}

.title__line {
	display: block;
	flex-grow: 1;
	height: 1px;
	margin: auto 20px auto 20px;
	background-color: $base-blue-3;
}

.about-me {
	display: flex;
	flex-direction: row;
}

.about-me__picture {
	max-width: 400px;
	max-height: 400px;
	width: 30%;
	height: 100%;
	min-width: 250px;
	min-height: 250px;
	border-radius: 30px;
	border: 5px solid $blue-contrast-1;
	box-shadow: -10px 5px $blue-contrast-3;
}

.about-me__description {
	width: 60%;
	margin-left: 50px;
	line-height: 1.6;
	text-align: justify;
	font-size: clamp(16px, 4vw, 20px);
}

@media (max-width: 480px) {
	.about-me {
		flex-direction: column;
		justify-content: center;
	}

	.about-me__description {
		width: 100%;
		margin: 50px auto 0 auto;
	}

	.about-me__picture {
		margin: 0 auto;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {
	.about-me {
		flex-direction: column;
		justify-content: center;

	}

	.about-me__description {
		width: 100%;
		margin: 50px auto 0 auto;
	}

	.about-me__picture {
		margin: 0 auto;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.about-me__description {
		padding: 0 30px;
	}

	.about-me__picture {
		margin: 0 auto;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
</style>

<template>
	<div style="display: flex; flex-direction: column">
		<div style="display: flex; flex-direction: row">
			<h1 class="title" id="project">Project</h1>
			<div class="title__line"></div>
		</div>
		<div class="project">
			<img :src="togara" alt="togara" class="project__image project__image--togara" />
			<div class="card">
				<div class="card__title">Togara</div>
				<div class="card__description">
					Restaurant reservation system in mobile android platform, built in Kotlin and Spring Boot.
				</div>
			</div>
		</div>
		<div class="project project--not-first">
			<img :src="turu" alt="turu" class="project__image" />
			<div class="card">
				<div class="card__title">Turu</div>
				<div class="card__description">
					A hotel booking service website application, built using Vue.js and Laravel.
				</div>
			</div>
		</div>
		<div class="project project--not-first">
			<img :src="anprCamera" alt="anpr-camera" class="project__image" />
			<div class="card">
				<div class="card__title">ANPR Camera Web</div>
				<div class="card__description">
					Providing a web application integrated with ANPR Camera services. Enable user to collect and manage
					vehicle related information such as plate number and timestamp.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Project",
	data() {
		return {
			anprCamera: require("../assets/anpr-camera.jpg"),
			turu: require("../assets/turu.jpg"),
			togara: require("../assets/togara.jpg"),
		};
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.project {
	display: grid;
	position: relative;
	grid-template-columns: repeat(12, 1fr);
}

.card {
	margin: auto;
	padding: 20px 50px;
	background-color: $base-blue-5;
	max-width: 400px;
	grid-row: 1;
	border-radius: 10px;
}

.card__title {
	font-size: 24px;
	color: $base-blue-2;
}

.card__description {
	margin-top: 10px;
	color: $base-blue-3;
}

.project__image {
	max-width: 100%;
	grid-row: 1;
}

.title {
	font-size: clamp(40px, 5vw, 60px);
}

@media (max-width: 480px) {
	.project:hover {
		transform: translateY(-6px);
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.project {
		transform: translateY(0px);
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		-webkit-box-shadow: 0px 5px 15px 5px $base-blue-7;
		box-shadow: 0px 5px 15px 5px $base-blue-7;
	}

	.card {
		grid-column: 1 / 13;
		background-color: transparent;
	}

	.card__title,
	.card__description {
		color: $white;
		text-align: center;
	}

	.card__title {
		font-weight: 700;
	}

	.project__image {
		z-index: -1;
		grid-column: 1 / 13;
		filter: brightness(50%);
	}

	.project--not-first {
		margin-top: 50px;
	}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 480px) and (max-width: 767.98px) {
	.project:hover {
		transform: translateY(-6px);
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}

	.project {
		transform: translateY(0px);
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
		-webkit-box-shadow: 0px 5px 15px 5px $base-blue-7;
		box-shadow: 0px 5px 15px 5px $base-blue-7;
	}

	.card {
		grid-column: 1 / 13;
		background-color: transparent;
	}

	.card__title,
	.card__description {
		color: $white;
		text-align: center;
	}

	.card__title {
		font-weight: 700;
	}

	.project__image {
		z-index: -1;
		grid-column: 1 / 13;
		filter: brightness(50%);
	}

	.project--not-first {
		margin-top: 50px;
	}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
	.card {
		grid-column: 1 / 6;
	}

	.project__image {
		grid-column: 4 / 13;
	}

	.project--not-first {
		margin-top: 60px;
	}
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
	.card {
		grid-column: 1 / 6;
	}

	.project__image {
		grid-column: 5 / 12;
	}

	.project--not-first {
		margin-top: 70px;
	}
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
	.card {
		grid-column: 3 / 6;
	}

	.project__image {
		grid-column: 5 / 10;
	}

	.project--not-first {
		margin-top: 80px;
	}
}
</style>
